import rootReducer from "./reducers";
import { createStore , applyMiddleware} from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
 
 let initialState ={}
 const middleware = [thunk]
 const store = createStore(rootReducer,initialState, composeWithDevTools(applyMiddleware(...middleware)));
//  const store = createStore(rootReducer , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
 export default store 
 