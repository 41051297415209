import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Responseloader from "./ResponseLoader";
import { GetIp } from "./Get_ip";
import "./myReactSelect.css";
import { Col, Row, Container, FormGroup, Input, Label } from "reactstrap";
import "./paymentPrint.css";
import "./baseForms.css";
import axios from "axios";
import { baseurl } from "../../../baseurl";

let companyId = localStorage.getItem("companyId");
let username = localStorage.getItem("username");
var token = localStorage.getItem("token");

export default function PartyOverview(props) {
  const [table, setTable] = useState({
    OverViewDataTable: [],
  });
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [printData, setPrintData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [noOfRows, setNoOfRows] = useState([]);
  const [nextPrintPage, setNextPrintPage] = useState(false);
  const [goBackUrl, setGoBackUrl] = useState([]);

  useEffect(() => {
    console.log("ffff", props, props.location.state?.id);
    // const id = props.location.state?.id;
    const id = 66;
    const url = props.location.state?.goBackUrl;
    setGoBackUrl(url);
    if (id) {
      getDataWithId(id);
    }
  }, []);
  const dynamicTopDesc = () => {
    return (
      <Row className="my-1 mt-1 page-header">
        <Container>
          <Row>
            <Col md="6">
              {" "}
              <h5>
                InvBookDesc:{" "}
                <span className="h5 text-muted">
                  {printData[0]?.inv_book_desc}
                </span>
              </h5>
            </Col>
            <Col md="6">
              {" "}
              <h5 className="voucherNo" style={{ textAlign: "right" }}>
                Voucher No:{" "}
                <span className="h5 text-muted">{printData[0]?.docNo}</span>
              </h5>
            </Col>
            <Col md="6">
              {" "}
              <h5 className="vendor">
                Vendor:{" "}
                <span className="h5 text-muted">
                  {printData[0]?.customerName}
                </span>
              </h5>
            </Col>
            <Col md="6">
              <h5 className="date" style={{ textAlign: "right" }}>
                Date :{" "}
                <span className="h5 text-muted">
                  {/* {moment(issueDate).format("MM DD YYYY")}   */}
                  {printData[0]?.date}
                </span>
              </h5>
            </Col>

            <Col md="6">
              {" "}
              <h5 className="BCE">
                Bank/cash/Employee:{" "}
                <span className="h5 text-muted">{printData[0]?.BANK_NAME}</span>
              </h5>
            </Col>
            <Col md="6">
              {" "}
              <h5 className="paidAmount" style={{ textAlign: "right" }}>
                Paid Amount :{" "}
                <span className="h5 text-muted">
                  {printData[0]?.paid_amount}
                </span>
              </h5>
            </Col>
            <Col md="12" style={{ marginTop: "-1.7rem" }}>
              <h5 className="unAppliedAmt" style={{ textAlign: "center" }}>
                Unapplied Amount :{" "}
                <span className="h5 text-muted">
                  {/* {moment(issueDate).format("MM DD YYYY")}   */}
                  {printData[0]?.unAppliedAmount}
                </span>
              </h5>
            </Col>
          </Row>
        </Container>
      </Row>
    );
  };
  const getDataWithId = (id) => {
    setLoadingResponse(true);
    axios
      .post(
        `${baseurl}/masterData/getSomOnePaidMeDataSupplier`,
        { receiptMtId: id, username: username },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => {
        let response = res.data.data;
        getFooterDataWithId(response[0]?.inv_book_id);
        console.log("hhhhhqq", response);

        // Calculate the sum of paid_amount values
        const sumOfPaidAmount = response.reduce(
          (total, item) => total + item.paid_amount,
          0
        );
        const unAppliedAmount = response[0]?.masterAmount - sumOfPaidAmount;

        console.log(
          "summmm",
          unAppliedAmount,
          response[0]?.masterAmount,
          sumOfPaidAmount
        );

        let label;
        if (response.page_size === "F") {
          label = "Full";
        } else {
          label = "Half";
        }
        const formValues = [];
        for (let i = 0; i < response.length; i++) {
          formValues.push({
            inv_book_desc: response[i].inv_book_desc,
            customerName: response[i].customerName,
            BANK_NAME: response[i].BANK_NAME,
            docNo: response[i].docNo,
            date: response[i].Date,
            invBookDesc: response[i].INV_BOOK_DESC,
            paid_amount: response[i].amount,
            unAppliedAmount: unAppliedAmount >= 0 ? unAppliedAmount : 0,
            purch_date: response[i].purch_date,
          });
        }

        console.log("hhhhhqq", formValues);
        let rowArray = [];
        const numberOfRows = Math.ceil(formValues.length / 3);
        console.log("numberOfRows", numberOfRows);
        for (let index = 0; index < numberOfRows; index++) {
          rowArray.push(index);
        }
        if (formValues.length > 18) {
          setNextPrintPage(true);
        }
        console.log("rowArray :>> ", rowArray);
        setNoOfRows(rowArray);
        setPrintData(formValues);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(function () {
        setLoadingResponse(false);
      });
  };
  const getFooterDataWithId = (id) => {
    setLoadingResponse(true);
    axios
      .post(
        `${baseurl}/masterData/getDocFooterSetupId`,
        { id, companyId: companyId, username: username },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => {
        let response = res.data.data[0];
        console.log("hhhhh", response);
        let label;
        if (response.page_size === "F") {
          label = "Full";
        } else {
          label = "Half";
        }
        const formValues = [];

        if (response.desc1 !== " ") {
          formValues.push({
            desc: response.desc1,
            enteredBy: response.Ent_by_1,
          });
        }
        if (response.desc_2 !== " ") {
          formValues.push({
            desc: response.desc_2,
            enteredBy: response.Ent_by_2,
          });
        }
        if (response.desc_3 !== " ") {
          formValues.push({
            desc: response.desc_3,
            enteredBy: response.Ent_by_3,
          });
        }
        if (response.desc_4 !== " ") {
          formValues.push({
            desc: response.desc_4,
            enteredBy: response.Ent_by_4,
          });
        }
        if (response.desc_5 !== " ") {
          formValues.push({
            desc: response.desc_5,
            enteredBy: response.Ent_by_5,
          });
        }
        if (response.desc_6 !== " ") {
          formValues.push({
            desc: response.desc_6,
            enteredBy: response.Ent_by_6,
          });
        }
        console.log("hhhhh>", formValues);
        setFooterData(formValues);
        // setData({
        //   ...data,
        //   docBook: { id: response.inv_book_id, label: response.INV_BOOK_DESC },
        //   pageSize: { id: response.page_size, label: label },
        //   formValues,
        // });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(function () {
        setLoadingResponse(false);
      });
  };

  const getUserIp = async function () {
    let res = await GetIp();
    console.log(res);
    return {
      userIp: res.ip,
      userCountry: res.country,
    };
  };

  return (
    <>
      {loadingResponse ? (
        <Responseloader />
      ) : (
        <div id="pdfContent page-container">
          <Row className="app-body-Invoice bg-white rounded-lg ">
            <Col xs="12" className="mb-5">
              {/* invoice print head ishtiaq tire inv print view 
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary "
                    id="printPageButton"
                    onClick={this.downloadInvoice}
                  >
                    Download Invoice
                  </button>
                </div>  */}
              <div id="pdfContent">
                {/* Element to appear at the top of every printed page */}
                {/* <div className="print-header">
                  <h2>Main Heading for Print</h2>
                </div> */}
                <br></br>
                <br></br>
                <Container>
                  <Row className=" ">
                    <Col md="6">
                      <h3>{localStorage.getItem("companyName")}</h3>
                    </Col>

                    <Col md="6">
                      <div style={{ textAlign: "end" }}>
                        <button
                          className="btn btn-primary mx-2 btn-sm print-half-size"
                          id="printPageButton"
                          onClick={() => {
                            window.print();
                          }}
                        >
                          Print to PDF!
                        </button>
                        {console.log("url...", goBackUrl, typeof goBackUrl)}
                        <Link
                          to={{
                            pathname: { goBackUrl },
                          }}
                        >
                          <button
                            className="btn btn-primary m-1  btn-sm"
                            id="printPageButton"
                            onClick={() => {
                              // this.goBackToInvoice();
                            }}
                          >
                            Back
                          </button>
                        </Link>
                      </div>
                      {/* <h3 className="text-right pr-2 my-0">
                      {invBookId === 1 ? "INVOICE: " : "Estimate: "}
                      <span className="text-muted"> {invoiceNo}</span>
                    </h3>
                    <hr className="border my-0  w-50 float-right " />
                    <br />
                    <h6 className="text-right text-muted">
                      9565 Folsom Blvd., Sacramento, CA 95827 <br />
                      Tel: (916)706-1745 • Fax: (916) 538-6346 <br />
                      folsomtireandwheels@hotmail.com{" "}
                    </h6> */}
                    </Col>
                  </Row>
                </Container>

                {dynamicTopDesc()}
                <Container>
                  <table className="my-doubleTable">
                    <thead>
                      <tr>
                        {[0, 1, 2].map((colIndex) => (
                          <React.Fragment key={colIndex}>
                            <th
                              colSpan="2"
                              style={{
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              Invoice
                            </th>
                            <th
                              colSpan="1"
                              style={{
                                border: "1px solid black",
                                textAlign: "center",
                              }}
                            >
                              Paid
                            </th>
                          </React.Fragment>
                        ))}
                      </tr>
                      <tr>
                        {[0, 1, 2].map((colIndex) => (
                          <React.Fragment key={colIndex}>
                            <th
                              style={{
                                border: "1px solid black",
                                width: "100px",
                                backgroundColor: "white",
                              }}
                            >
                              No.
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                backgroundColor: "white",
                              }}
                            >
                              Date
                            </th>
                            <th
                              style={{
                                border: "1px solid black",
                                borderTop: "none",
                                textAlign: "center",
                                backgroundColor: "white",
                              }}
                            >
                              Amount
                            </th>
                          </React.Fragment>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {console.log("setNoOfRows :>> ", noOfRows)}
                      {noOfRows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {[0, 1, 2].map((colIndex) => {
                            const dataIndex = rowIndex * 3 + colIndex;
                            const currentRow = printData[dataIndex];

                            return (
                              <React.Fragment key={colIndex}>
                                <td style={{ border: "1px solid black" }}>
                                  {currentRow?.docNo}
                                </td>
                                <td style={{ border: "1px solid black" }}>
                                  {currentRow?.purch_date}
                                </td>
                                <td
                                  style={{
                                    textAlign: "right",
                                    border: "1px solid black",
                                  }}
                                >
                                  {currentRow?.paid_amount != ""
                                    ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(currentRow?.paid_amount)
                                    : null}
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Container>

                <Container
                  className={`${nextPrintPage ? "footerNewPage" : "footer"}`}
                >
                  {/* <Col md="4">
                    <h4>Naration:</h4>
                  </Col>
                  <Col
                    md="12"
                    className="border"
                    style={{ height: "4rem", marginBottom: "5rem" }}
                  >
                    <p>{printData[0]?.naration}</p>
                  </Col> */}
                  <Row
                    className=""
                    style={{ textAlign: "center", marginTop: "5rem" }}
                  >
                    {footerData.map((row) => (
                      <Col
                        lg="4"
                        md="4"
                        sm="4"
                        className="px-5"
                        style={{
                          marginBottom: "3rem",
                          marginTop: row.enteredBy != 1 ? "0" : "0",
                        }}
                      >
                        <h5> {row.enteredBy ? printData[0]?.name : "  "}</h5>
                        <hr
                          style={{
                            border: "1px solid ",
                            // marginBottom: "3vh",
                            // marginTop: "1vh",
                            borderColor: "E4E5E6",
                            margin: "revert",
                          }}
                        />
                        <h4 key={row.id}>{row.desc}</h4>
                      </Col>
                    ))}
                    {/* <table className="my-table mt-4">
                      <thead>
                        <tr>
                          <th>Prepared By</th>
                          <th>Entered By</th>
                            <th>Approved By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {footerData.map((row) => (
                          <tr key={row.id}>
                            <td>{row.desc}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table> */}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>

          {/* Element to appear at the end of the last printed page */}
          {/* <div className="end-of-last-page">
            <h4>
              This content appears at the end of the last page when printed.
            </h4>
          </div> */}
        </div>
      )}
    </>
  );
}
