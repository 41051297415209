export const token = localStorage.getItem("token");
export const username = localStorage.getItem("username");
export const companyId = localStorage.getItem("companyId");
export const currencyId = localStorage.getItem("currencyId");
export const companyCurrencySymbol = localStorage.getItem(
  "companySymbolCurrency"
);

// this header is used for authentication
export const headers = {
  headers: {
    authorization: token,
  },
};
