import {
  PARTY_REQUEST_GET,
  PARTY_SUCCESS_GET,
  PARTY_RESET_GET,
  PARTY_FAIL_GET,
  NEW_PARTY_REQUEST,
  NEW_PARTY_SUCCESS,
  NEW_PARTY_RESET,
  NEW_PARTY_FAIL,
  UPDATE_PARTY_REQUEST,
  UPDATE_PARTY_SUCCESS,
  UPDATE_PARTY_FAIL,
  UPDATE_PARTY_RESET,
  DELETE_PARTY_SUCCESS,
  DELETE_PARTY_FAIL,
  DELETE_PARTY_RESET,
  DELETE_PARTY_REQUEST,
  CLEAR_ERRORS,
} from "../../constants/RealEstate/partyConstants";
// export const AddProductionTypeReducer = (state = { product: {} }, action) => {
//   switch (action.type) {
//     case NEW_PARTY_REQUEST:
//       return {
//         ...state,
//         loading: true,
//       };
//     case NEW_PARTY_SUCCESS:
//       return {
//         loading: false,
//         success: action.payload.message,
//         product: action.payload,
//       };
//     case NEW_PARTY_FAIL:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload,
//       };
//     case NEW_PARTY_RESET:
//       return {
//         ...state,
//         success: false,
//       };
//     case CLEAR_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };
//     default:
//       return state;
//   }
// };
export const getParty = (state = { parties: {} }, action) => {
  switch (action.type) {
    case PARTY_REQUEST_GET:
      return {
        ...state,
        loading: true,
      };
    case PARTY_SUCCESS_GET:
      return {
        loading: false,
        productionType: action.payload.data,
      };
    case PARTY_FAIL_GET:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PARTY_RESET_GET:
      return {
        // ...state,
        productionType: {},
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const ProductionTypeReducer = (
  state = { productionType: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_PARTY_REQUEST:
    case DELETE_PARTY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PARTY_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PARTY_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PARTY_FAIL:
    case UPDATE_PARTY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PARTY_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_PARTY_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
