import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Responseloader from "./ResponseLoader";
import { GetIp } from "./Get_ip";
import "./myReactSelect.css";
import { Col, Row, Container, FormGroup, Input, Label } from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import "./formsTable.css";
import "./baseForms.css";
import axios from "axios";
import { baseurl } from "../../../baseurl";

let companyId = localStorage.getItem("companyId");
let username = localStorage.getItem("username");
var token = localStorage.getItem("token");

export default function PartyOverview(props) {
  const [table, setTable] = useState({
    OverViewDataTable: [],
  });
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [printData, setPrintData] = useState([]);
  const [footerData, setFooterData] = useState([]);
  const [nextPrintPage, setNextPrintPage] = useState([]);
  const [nextPrintPageFooter, setNextPrintPageFooter] = useState([]);

  useEffect(() => {
    console.log("ffff", props, props.location.state?.rowId);
    console.log("props", props.location.state?.rowId);
    const id = props.location.state?.rowId;
    // const id = 410;
    const url = props.location.state?.goBackUrl;
    if (id) {
      getDataWithId(id);
    }
  }, []);

  const dynamicTopDesc = () => {
    return (
      <>
        <Row className="print-header bg-white">
          <Container>
            <Row style={{ backgroundColor: "white" }}>
              <Col style={{ backgroundColor: "white" }} md="4">
                <h3 className="companyName" style={{ textAlign: "left" }}>
                  {localStorage.getItem("companyName")}
                </h3>
              </Col>
              <Col style={{ backgroundColor: "white" }} md="4">
                {" "}
                <h5 className="invBookDesc" style={{ textAlign: "center" }}>
                  {" "}
                  <span className="h5">{printData[0]?.invBookDesc}</span>
                </h5>
              </Col>
              <Col style={{ backgroundColor: "white" }} md="4">
                {" "}
                <h5 className="voucherNo" style={{ textAlign: "right" }}>
                  Voucher No:{" "}
                  <span className="h5 text-muted">{printData[0]?.docNo}</span>
                </h5>
              </Col>
              <Col style={{ backgroundColor: "white" }} md="12">
                <h5 style={{ textAlign: "right", marginTop: "-3px" }}>
                  Date :{" "}
                  <span className="h5 text-muted">{printData[0]?.date}</span>
                </h5>
              </Col>
            </Row>
          </Container>
        </Row>
      </>
    );
  };
  const getDataWithId = (id) => {
    let totalRecordCheckForPrint;
    setLoadingResponse(true);
    axios
      .post(
        `${baseurl}/masterData/getJournalDetail`,
        { id, username: username },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => {
        let response = res.data.data;

        console.log("getFooterDataWithId", getFooterDataWithId);
        console.log("hhhhhqq", response);

        const originalDate = response[0].date;
        const formattedDate = formatDateDMY(originalDate);

        function formatDateDMY(inputDate) {
          // Create a Date object from the input date string
          const dateObj = new Date(inputDate);

          // Extract day, month, and year
          const day = String(dateObj.getDate()).padStart(2, "0");
          const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const year = dateObj.getFullYear();

          // Assemble the formatted date string in "dd-mm-yyyy" format
          const formattedDate = `${day}-${month}-${year}`;

          return formattedDate;
        }

        console.log(formattedDate);

        let label;
        if (response.page_size === "F") {
          label = "Full";
        } else {
          label = "Half";
        }
        const formValuesjournalDet = [];
        console.log(response[0]);
        for (let i = 0; i < response.length; i++) {
          formValuesjournalDet.push({
            actCode: response[i].acc_lov_id,
            concatenatedCode:
              response[i].customer_id ||
              response[i].employee_id ||
              response[i].supplier_id ||
              response[i].sub_acct_id ||
              response[i].sub_inv_id ||
              response[i].cost_center_id,
            desc: response[i].acc_lov_desc,
            desc2: response[i].custom_remarks,
            concatenateddesc:
              response[i].COMPANY_NAME ||
              response[i].employee_name ||
              response[i].supplier_name ||
              response[i].sub_acct_desc ||
              response[i].sub_inv_desc ||
              response[i].cost_center_desc,
            debit: response[i].debit,
            credit: response[i].credit,
            docNo: response[i].docNo,
            naration: response[i].reason,
            date: formattedDate,
            invBookDesc: response[i].INV_BOOK_DESC,
            name: response[i].Name,
          });
        }
        // for (let i = 0; i < 6; i++) {
        //   formValuesjournalDet.push({
        //     actCode: "hello",
        //     desc: "hello",
        //     desc2: "hello",
        //     debit: "hello",
        //     credit: "hello",
        //     docNo: "hello",
        //     naration: "hello",
        //     date: formattedDate,
        //     invBookDesc: "hello",
        //     name: "hello",
        //   });
        // }
        console.log("hhhhhqq", formValuesjournalDet);
        totalRecordCheckForPrint = formValuesjournalDet.length;
        setPrintData(formValuesjournalDet);
        return getFooterDataWithId(response[0]?.INV_BOOK_ID);
      })
      .then((footerLength) => {
        // The length of formValuesFooterData from getFooterDataWithId is available here
        console.log("Footer length:", footerLength);
        console.log("totalRecordCheckForPrint:", totalRecordCheckForPrint);
        if (footerLength >= 4) {
          if (totalRecordCheckForPrint > 2 && totalRecordCheckForPrint < 9) {
            setNextPrintPage(true);
          } else if (
            totalRecordCheckForPrint > 10 &&
            totalRecordCheckForPrint < 17
          ) {
            setNextPrintPage(true);
          } else if (
            totalRecordCheckForPrint > 18 &&
            totalRecordCheckForPrint < 25
          ) {
            setNextPrintPage(true);
          } else if (
            totalRecordCheckForPrint > 26 &&
            totalRecordCheckForPrint < 32
          ) {
            setNextPrintPage(true);
          }
        } else {
          if (totalRecordCheckForPrint > 4 && totalRecordCheckForPrint < 9) {
            setNextPrintPage(true);
          } else if (
            totalRecordCheckForPrint > 12 &&
            totalRecordCheckForPrint < 17
          ) {
            setNextPrintPage(true);
          } else if (
            totalRecordCheckForPrint > 20 &&
            totalRecordCheckForPrint < 25
          ) {
            setNextPrintPage(true);
          } else if (
            totalRecordCheckForPrint > 29 &&
            totalRecordCheckForPrint < 34
          ) {
            setNextPrintPage(true);
          } else {
            setNextPrintPage(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(function () {
        setLoadingResponse(false);
      });
  };
  const getFooterDataWithId = (id) => {
    setLoadingResponse(true);
    return axios
      .post(
        `${baseurl}/masterData/getDocFooterSetupId`,
        { id, companyId: companyId, username: username },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => {
        let response = res.data.data[0];
        console.log("hhhhh", response);
        let label;
        if (response.page_size === "F") {
          label = "Full";
        } else {
          label = "Half";
        }
        const formValuesFooterData = [];

        if (response.desc1 !== " ") {
          formValuesFooterData.push({
            desc: response.desc1,
            enteredBy: response.Ent_by_1,
          });
        }
        if (response.desc_2 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_2,
            enteredBy: response.Ent_by_2,
          });
        }
        if (response.desc_3 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_3,
            enteredBy: response.Ent_by_3,
          });
        }
        if (response.desc_4 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_4,
            enteredBy: response.Ent_by_4,
          });
        }
        if (response.desc_5 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_5,
            enteredBy: response.Ent_by_5,
          });
        }
        if (response.desc_6 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_6,
            enteredBy: response.Ent_by_6,
          });
        }
        console.log("hhhhh>", formValuesFooterData);
        if (formValuesFooterData.length > 3) {
          setNextPrintPageFooter(true);
        } else {
          setNextPrintPageFooter(false);
        }
        setFooterData(formValuesFooterData);
        return formValuesFooterData.length;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(function () {
        setLoadingResponse(false);
      });
  };

  const getUserIp = async function () {
    let res = await GetIp();
    console.log(res);
    return {
      userIp: res.ip,
      userCountry: res.country,
    };
  };

  return (
    <>
      {loadingResponse ? (
        <Responseloader />
      ) : (
        <div id="pdfContent page-container">
          <Row className="app-body-Invoice bg-white rounded-lg ">
            <Col xs="12" className="mb-5">
              {/* invoice print head ishtiaq tire inv print view 
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary "
                    id="printPageButton"
                    onClick={this.downloadInvoice}
                  >
                    Download Invoice
                  </button>
                </div>  */}
              <div id="pdfContent">
                {/* Element to appear at the top of every printed page */}
                {/* <div className="print-header">
                  <h2>Main Heading for Print</h2>
                </div> */}
                <br></br>
                <br></br>
                <Container>
                  <Row className="">
                    {/* <Col md="6" style={{ zIndex: "50" }}>
                      <h3>{localStorage.getItem("companyName")}</h3>
                    </Col> */}

                    <Col md="12">
                      <div style={{ textAlign: "end" }}>
                        <button
                          className="btn btn-primary mx-2 btn-sm print-half-size"
                          id="printPageButton"
                          onClick={() => {
                            window.print();
                          }}
                        >
                          Print to PDF!
                        </button>
                        <Link
                          to={{
                            pathname: "/OverviewManualJournal",
                          }}
                        >
                          <button
                            className="btn btn-primary m-1  btn-sm"
                            id="printPageButton"
                            onClick={() => {}}
                          >
                            Back
                          </button>
                        </Link>
                      </div>
                      {/* <h3 className="text-right pr-2 my-0">
                      {invBookId === 1 ? "INVOICE: " : "Estimate: "}
                      <span className="text-muted"> {invoiceNo}</span>
                    </h3>
                    <hr className="border my-0  w-50 float-right " />
                    <br />
                    <h6 className="text-right text-muted">
                      9565 Folsom Blvd., Sacramento, CA 95827 <br />
                      Tel: (916)706-1745 • Fax: (916) 538-6346 <br />
                      folsomtireandwheels@hotmail.com{" "}
                    </h6> */}
                    </Col>
                  </Row>
                </Container>
                <div>{dynamicTopDesc()}</div>
                <Row className="my-1 main-content">
                  <Container style={{ marginTop: "-5rem" }}>
                    {/*(uper container ki he ye class)   className="border" style={{ height: "28rem" }} */}
                    <table className="my-table">
                      <thead>
                        <tr style={{ backgroundColor: "white" }}>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr style={{ backgroundColor: "white" }}>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr style={{ backgroundColor: "white" }}>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr style={{ backgroundColor: "white" }}>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr style={{ backgroundColor: "white" }}>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr style={{ backgroundColor: "white" }}>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr style={{ backgroundColor: "white" }}>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th></th>
                        </tr>
                        <tr>
                          <th
                            style={{
                              border: "1px solid black",
                              width: "100px",
                            }}
                          >
                            Account Code
                          </th>
                          <th style={{ border: "1px solid black" }}>
                            Description
                          </th>
                          <th style={{ border: "1px solid black" }}>Debit</th>
                          <th style={{ border: "1px solid black" }}>Credit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {printData.map((row, index) => (
                          <tr key={row.id}>
                            <td style={{ border: "1px solid black" }}>
                              {row.actCode}
                              {row.concatenatedCode
                                ? ` (${row.concatenatedCode})`
                                : ""}
                            </td>

                            <td style={{ border: "1px solid black" }}>
                              {row.desc}
                              {row.concatenateddesc
                                ? ` (${row.concatenateddesc})`
                                : ""}
                              <br />
                              <span style={{ fontSize: "12px" }}>
                                {row.desc2}
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                border: "1px solid black",
                              }}
                            >
                              {row.debit !== "" &&
                              row.debit !== null &&
                              row.debit !== undefined
                                ? new Intl.NumberFormat("en-US", {
                                    style: "decimal", // Use "decimal" style instead of "currency"
                                    minimumFractionDigits: 2, // Keep two decimal places
                                    maximumFractionDigits: 2,
                                    useGrouping: true, // This keeps the thousand separator formatting
                                  }).format(parseFloat(row.debit))
                                : null}
                            </td>

                            <td
                              style={{
                                textAlign: "right",
                                border: "1px solid black",
                              }}
                            >
                              {row.credit !== "" &&
                              row.credit !== null &&
                              row.credit !== undefined
                                ? new Intl.NumberFormat("en-US", {
                                    style: "decimal", // Use "decimal" style instead of "currency"
                                    minimumFractionDigits: 2, // Keep two decimal places
                                    maximumFractionDigits: 2,
                                    useGrouping: true, // This keeps the thousand separator formatting
                                  }).format(parseFloat(row.credit))
                                : null}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                            colSpan="2"
                          >
                            Totals
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal", // Use "decimal" style instead of "currency"
                              minimumFractionDigits: 2, // Keep two decimal places
                              maximumFractionDigits: 2,
                              useGrouping: true, // This
                            }).format(
                              printData.reduce(
                                (sum, row) =>
                                  sum +
                                  (row.debit !== "" &&
                                  row.debit !== null &&
                                  row.debit !== undefined
                                    ? parseFloat(row.debit)
                                    : 0),
                                0
                              )
                            )}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              border: "1px solid black",
                              fontWeight: "bold",
                            }}
                          >
                            {new Intl.NumberFormat("en-US", {
                              style: "decimal", // Use "decimal" style instead of "currency"
                              minimumFractionDigits: 2, // Keep two decimal places
                              maximumFractionDigits: 2,
                              useGrouping: true, // This
                            }).format(
                              printData.reduce(
                                (sum, row) =>
                                  sum +
                                  (row.credit !== "" &&
                                  row.credit !== null &&
                                  row.credit !== undefined
                                    ? parseFloat(row.credit)
                                    : 0),
                                0
                              )
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Container>
                </Row>
                <Container
                  className={`${nextPrintPage ? "footerNewPage" : "footer"}`}
                  // className="footer"
                  style={{ backgroundColor: "white" }}
                >
                  <Col md="4">
                    <h4>Naration:</h4>
                  </Col>
                  <Col
                    md="12"
                    className="border"
                    style={{ height: "4rem", marginBottom: "5rem" }}
                  >
                    <p>{printData[0]?.naration}</p>
                  </Col>
                  <Row
                    className="mt-5 footerRows"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {footerData.map((row) => (
                      <Col
                        lg="4"
                        md="4"
                        sm="4"
                        className="px-5"
                        style={{
                          marginBottom: "3rem",
                          marginTop: row.enteredBy != 1 ? "1.3rem" : "0",
                        }}
                      >
                        <h5> {row.enteredBy ? printData[0]?.name : "  "}</h5>
                        <hr
                          style={{
                            border: "1px solid ",
                            borderColor: "E4E5E6",
                            margin: "revert",
                          }}
                        />
                        <h4 key={row.id}>{row.desc}</h4>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>

          {/* Element to appear at the end of the last printed page */}
          {/* <div className="end-of-last-page">
            <h4>
              This content appears at the end of the last page when printed.
            </h4>
          </div> */}
        </div>
      )}
    </>
  );
}
