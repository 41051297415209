import {
  NEW_PRODUCTION_TYPE_REQUEST,
  NEW_PRODUCTION_TYPE_SUCCESS,
  NEW_PRODUCTION_TYPE_RESET,
  NEW_PRODUCTION_TYPE_FAIL,
  PRODUCTION_TYPE_REQUEST_GET_BY_ID,
  PRODUCTION_TYPE_SUCCESS_GET_BY_ID,
  PRODUCTION_TYPE_RESET_GET_BY_ID,
  PRODUCTION_TYPE_FAIL_GET_BY_ID,
  UPDATE_PRODUCTION_TYPE_REQUEST,
  UPDATE_PRODUCTION_TYPE_SUCCESS,
  UPDATE_PRODUCTION_TYPE_FAIL,
  UPDATE_PRODUCTION_TYPE_RESET,
  DELETE_PRODUCTION_TYPE_SUCCESS,
  DELETE_PRODUCTION_TYPE_FAIL,
  DELETE_PRODUCTION_TYPE_RESET,
  DELETE_PRODUCTION_TYPE_REQUEST,
  CLEAR_ERRORS
} 
  from '../constants/productionConstants.js';
export const AddProductionTypeReducer = (state = { product: {} }, action) => {
    switch (action.type) {
      case NEW_PRODUCTION_TYPE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_PRODUCTION_TYPE_SUCCESS:
        return {
          loading: false,
          success: action.payload.message,
          product: action.payload,
        };
      case NEW_PRODUCTION_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_PRODUCTION_TYPE_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
export const getProductionTypeById = (state = { productionType: {} }, action) => {
    switch (action.type) {
      case PRODUCTION_TYPE_REQUEST_GET_BY_ID:
        return {
          ...state,
          loading: true,
        };
      case PRODUCTION_TYPE_SUCCESS_GET_BY_ID:
        return {
          loading: false,
          productionType: action.payload.data[0],
        };
      case PRODUCTION_TYPE_FAIL_GET_BY_ID:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case PRODUCTION_TYPE_RESET_GET_BY_ID:
        return {
          // ...state,
          productionType:{},
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  export const ProductionTypeReducer = (state = { productionType: {} }, action) => {
    switch (action.type) {
      case UPDATE_PRODUCTION_TYPE_REQUEST:
      case DELETE_PRODUCTION_TYPE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_PRODUCTION_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
  
      case UPDATE_PRODUCTION_TYPE_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: action.payload,
        };
      case DELETE_PRODUCTION_TYPE_FAIL:
      case UPDATE_PRODUCTION_TYPE_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DELETE_PRODUCTION_TYPE_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case UPDATE_PRODUCTION_TYPE_RESET:
        return {
          ...state,
          isUpdated: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };