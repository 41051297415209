export const NEW_PARTY_REQUEST = "NEW_PARTY_REQUEST";
export const NEW_PARTY_SUCCESS = "NEW_PARTY_SUCCESS";
export const NEW_PARTY_RESET = "NEW_PARTY_RESET";
export const NEW_PARTY_FAIL = "NEW_PARTY_FAIL";

export const UPDATE_PARTY_REQUEST = "UPDATE_PARTY_REQUEST";
export const UPDATE_PARTY_SUCCESS = "UPDATE_PARTY_SUCCESS";
export const UPDATE_PARTY_RESET = "UPDATE_PARTY_RESET";
export const UPDATE_PARTY_FAIL = "UPDATE_PARTY_FAIL";

export const DELETE_PARTY_REQUEST = "DELETE_PARTY_REQUEST";
export const DELETE_PARTY_SUCCESS = "DELETE_PARTY_SUCCESS";
export const DELETE_PARTY_RESET = "DELETE_PARTY_RESET";
export const DELETE_PARTY_FAIL = "DELETE_PARTY_FAIL";

export const PARTY_REQUEST_GET = "PARTY_REQUEST_GET";
export const PARTY_SUCCESS_GET = "PARTY_SUCCESS_GET";
export const PARTY_RESET_GET = "PARTY_RESET_GET";
export const PARTY_FAIL_GET = "PARTY_FAIL_GET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
