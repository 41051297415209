export const NEW_PRODUCTION_TYPE_REQUEST = "NEW_PRODUCTION_TYPE_REQUEST";
export const NEW_PRODUCTION_TYPE_SUCCESS = "NEW_PRODUCTION_TYPE_SUCCESS";
export const NEW_PRODUCTION_TYPE_RESET = "NEW_PRODUCTION_TYPE_RESET";
export const NEW_PRODUCTION_TYPE_FAIL = "NEW_PRODUCTION_TYPE_FAIL";

export const UPDATE_PRODUCTION_TYPE_REQUEST = "UPDATE_PRODUCTION_TYPE_REQUEST";
export const UPDATE_PRODUCTION_TYPE_SUCCESS = "UPDATE_PRODUCTION_TYPE_SUCCESS";
export const UPDATE_PRODUCTION_TYPE_RESET = "UPDATE_PRODUCTION_TYPE_RESET";
export const UPDATE_PRODUCTION_TYPE_FAIL = "UPDATE_PRODUCTION_TYPE_FAIL";

export const DELETE_PRODUCTION_TYPE_REQUEST = "DELETE_PRODUCTION_TYPE_REQUEST";
export const DELETE_PRODUCTION_TYPE_SUCCESS = "DELETE_PRODUCTION_TYPE_SUCCESS";
export const DELETE_PRODUCTION_TYPE_RESET = "DELETE_PRODUCTION_TYPE_RESET";
export const DELETE_PRODUCTION_TYPE_FAIL = "DELETE_PRODUCTION_TYPE_FAIL";

export const PRODUCTION_TYPE_REQUEST_GET_BY_ID = "GET_BY_ID_PRODUCTION_TYPE_REQUEST_GET_BY_ID";
export const PRODUCTION_TYPE_SUCCESS_GET_BY_ID = "GET_BY_ID_PRODUCTION_TYPE_SUCCESS_GET_BY_ID";
export const PRODUCTION_TYPE_RESET_GET_BY_ID = "GET_BY_ID_PRODUCTION_TYPE_RESET_GET_BY_ID";
export const PRODUCTION_TYPE_FAIL_GET_BY_ID = "GET_BY_ID_PRODUCTION_TYPE_FAIL_GET_BY_ID";

export const CLEAR_ERRORS = "CLEAR_ERRORS";