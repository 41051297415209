import {
  CLEAR_ERRORS,
  GET_MODULE_TYPE_FAIL,
  GET_MODULE_TYPE_REQUEST,
  GET_MODULE_TYPE_RESET,
  GET_MODULE_TYPE_SUCCESS,
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
  GET_MENU_FAIL,
  GET_MENU_RESET,
} from "../constants/utilsConstants";

export const getModuleType = (state = { moduleType: [] }, action) => {
  switch (action.type) {
    case GET_MODULE_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MODULE_TYPE_SUCCESS:
      return {
        loading: false,
        moduleType: action.payload,
      };
    case GET_MODULE_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_MODULE_TYPE_RESET:
      return {
        moduleType: {},
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
export const getMenuOptions = (state = { menuOptions: [] }, action) => {
  switch (action.type) {
    case GET_MENU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MENU_SUCCESS:
      return {
        loading: false,
        menuOptions: action.payload?.data,
      };
    case GET_MENU_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_MENU_RESET:
      return {
        menuOptions: {},
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
