export const GET_MODULE_TYPE_REQUEST = "GET_MODULE_TYPE_REQUEST";
export const GET_MODULE_TYPE_SUCCESS = "GET_MODULE_TYPE_SUCCESS";
export const GET_MODULE_TYPE_RESET = "GET_MODULE_TYPE_RESET";
export const GET_MODULE_TYPE_FAIL = "GET_MODULE_TYPE_FAIL";

export const GET_MENU_REQUEST = "GET_MENU_REQUEST";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_FAIL = "GET_MENU_FAIL";
export const GET_MENU_RESET = "GET_MENU_RESET";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
