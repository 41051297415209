///                         Gradient Loader

// import React from "react";
// import Loader from "react-loader-spinner";
// import { Container, Row } from "reactstrap";

// const Responseloader = () => {
//   const overlayStyles = {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     zIndex: 1000,
//   };

//   const loaderContainerStyles = {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     padding: "20px",
//     borderRadius: "10px",
//     boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Shadow for the loader
//     width: "80px",
//     height: "80px",
//     background:
//       "conic-gradient(from 0deg, #3498db, #2ecc71, #e74c3c, #ff6347, #3498db)",
//     backgroundSize: "300% 300%",
//     animation: "rotate 3s linear infinite, gradient-shift 3s linear infinite",
//   };

//   // Inline keyframes animation for rotation
//   const rotationKeyframes = `
//     @keyframes rotate {
//       0% { transform: rotate(0deg); }
//       100% { transform: rotate(360deg); }
//     }
//   `;

//   // Inline keyframes animation for gradient shift
//   const gradientShiftKeyframes = `
//     @keyframes gradient-shift {
//       0% { background-position: 0% 50%; }
//       100% { background-position: 100% 50%; }
//     }
//   `;

//   const styleSheet = document.styleSheets[0];
//   styleSheet.insertRule(rotationKeyframes, styleSheet.cssRules.length);
//   styleSheet.insertRule(gradientShiftKeyframes, styleSheet.cssRules.length);

//   return (
//     <div style={overlayStyles}>
//       <Container>
//         <Row className="centered d-flex flex-column">
//           <div style={loaderContainerStyles}>
//             <Loader
//               type="Oval"
//               color="transparent" // Set the color to transparent
//               height={80}
//               width={80}
//             />
//           </div>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default Responseloader;

///                           Circle Loader Red Rotations

// import React from "react";
// import Loader from "react-loader-spinner";
// import { Container, Row } from "reactstrap";

// const Responseloader = () => {
//   const overlayStyles = {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     zIndex: 1000,
//   };

//   const loaderContainerStyles = {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     padding: "20px",
//     borderRadius: "10px",
//     boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Shadow for the loader
//   };

//   const loaderAnimationStyles = {
//     animation: "color-rotate 3s linear infinite",
//   };

//   // Define a keyframes animation for color and rotation
//   const keyframes = `
//     @keyframes color-rotate {
//       0% { transform: rotate(0deg); color: #ff6347; } /* Tomato */
//       25% { color: #3498db; } /* Dodger Blue */
//       50% { color: #2ecc71; } /* Emerald */
//       75% { color: #e74c3c; } /* Alizarin */
//       100% { transform: rotate(360deg); color: #ff6347; } /* Tomato */
//     }
//   `;

//   const styleSheet = document.styleSheets[0];
//   styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

//   return (
//     <div style={overlayStyles}>
//       <Container>
//         <Row className="centered d-flex flex-column">
//           <div style={loaderContainerStyles}>
//             <Loader
//               type="Oval"
//               color="#ff6347" // Initial color for the loader icon (Tomato)
//               height={80}
//               width={80}
//               style={loaderAnimationStyles}
//             />
//           </div>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default Responseloader;

//                          yellow rotations
// import React from "react";
// import Loader from "react-loader-spinner";
// import { Container, Row } from "reactstrap";

// const Responseloader = () => {
//   const overlayStyles = {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     zIndex: 1000,
//   };

//   const loaderContainerStyles = {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     padding: "20px",
//     borderRadius: "10px",
//     boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Shadow for the loader
//   };

//   const loaderAnimationStyles = {
//     animation: "color-rotate 3s linear infinite",
//   };

//   // Define a keyframes animation for color and rotation
//   const keyframes = `
//     @keyframes color-rotate {
//       0% { transform: rotate(0deg); color: #ffff00; } /* Yellow */
//       25% { color: #3498db; } /* Dodger Blue */
//       50% { color: #2ecc71; } /* Emerald */
//       75% { color: #0000ff; } /* Blue */
//       100% { transform: rotate(360deg); color: #ffff00; } /* Yellow */
//     }
//   `;

//   const styleSheet = document.styleSheets[0];
//   styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

//   return (
//     <div style={overlayStyles}>
//       <Container>
//         <Row className="centered d-flex flex-column">
//           <div style={loaderContainerStyles}>
//             <Loader
//               type="Oval"
//               color="#ffff00" // Initial color for the loader icon (Yellow)
//               height={80}
//               width={80}
//               style={loaderAnimationStyles}
//             />
//           </div>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default Responseloader;

//                  blue rotations

import React from "react";
import Loader from "react-loader-spinner";
import { Container, Row } from "reactstrap";

const Responseloader = (props) => {
  console.log(">>>>", props);
  const overlayStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  };

  const loaderContainerStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderRadius: "10px",
    position: "relative", // Position relative for the loader container
    // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)", // Shadow for the loader
  };

  const loaderAnimationStyles = {
    animation: "color-rotate 3s linear infinite",
  };

  const loaderPercentageStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#FFF",
    zIndex: 1,
  };

  // Define a keyframes animation for color and rotation
  const keyframes = `
    @keyframes color-rotate {
      0% { transform: rotate(0deg); color: #442B84; } /* Your specified color */
      25% { color: #3498db; } /* Dodger Blue */
      50% { color: #2ecc71; } /* Emerald */
      75% { color: #e74c3c; } /* Alizarin */
      100% { transform: rotate(360deg); color: #442B84; } /* Your specified color */
    }
  `;

  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

  return (
    <div style={overlayStyles}>
      <Container>
        <Row className="centered d-flex flex-column">
          <div style={loaderContainerStyles}>
            <Loader
              type="Oval"
              color="#F3D732" // Initial color for the loader icon
              height={80}
              width={80}
              style={loaderAnimationStyles}
            />
            {props?.percentage ? (
              <div style={loaderPercentageStyles}>50%</div>
            ) : null}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Responseloader;

//                  three  rotating circles
// import React from "react";

// const Responseloader = () => {
//   const overlayStyles = {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     width: "100%",
//     height: "100%",
//     backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     zIndex: 1000,
//   };

//   const loaderStyles = {
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     width: "100px",
//     height: "100px",
//     animation: "rotate 1.5s linear infinite",
//   };

//   const circleStyles = {
//     width: "20px",
//     height: "20px",
//     backgroundColor: "#31328A", // Desired color (#462A83)
//     borderRadius: "50%",
//     margin: "0 5px",
//     animation: "bounce 1s infinite alternate",
//   };

//   // Define a keyframes animation for rotation
//   const rotateKeyframes = `
//     @keyframes rotate {
//       0% { transform: rotate(0deg); }
//       100% { transform: rotate(360deg); }
//     }
//   `;

//   // Define a keyframes animation for bouncing
//   const bounceKeyframes = `
//     @keyframes bounce {
//       0% { transform: translateY(0); }
//       100% { transform: translateY(-20px); }
//     }
//   `;

//   const styleSheet = document.styleSheets[0];
//   styleSheet.insertRule(rotateKeyframes, styleSheet.cssRules.length);
//   styleSheet.insertRule(bounceKeyframes, styleSheet.cssRules.length);

//   return (
//     <div style={overlayStyles}>
//       <div style={loaderStyles}>
//         <div style={circleStyles}></div>
//         <div style={circleStyles}></div>
//         <div style={circleStyles}></div>
//       </div>
//     </div>
//   );
// };

// export default Responseloader;

//
