import {
  AddProductionTypeReducer,
  getProductionTypeById,
  ProductionTypeReducer,
} from "./productionReducer";
import { getModuleType, getMenuOptions } from "./utilsReducer";
import { getParty } from "./RealEstate/productionReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  AddProductionTypeReducer,
  getProductionTypeById,
  ProductionTypeReducer,
  getParty,
  getModuleType,
  getMenuOptions,
});
export default rootReducer;
