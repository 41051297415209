import React, { Component } from "react";
import ReactRoundedImage from "react-rounded-image";
import logo from "../../assets/img/brand/wizmen.png";
import FolsomLogo from "../../assets/img/FolsomLogo.png";
import { Col, Row, Container, FormGroup, Input, Label } from "reactstrap";
import { baseurl } from "../../baseurl.js";
import { headers, companyId, username, token } from "../../utils/variables";
import { Redirect } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Responseloader from "./ShortCutComponents/ResponseLoader";

var thisContex;
// var token = localStorage.getItem("token");
// var username = localStorage.getItem("username");
var UserName = localStorage.getItem("firstName");
// var token = localStorage.getItem("token");
// var companyId = localStorage.getItem("companyId");
var companylog = localStorage.getItem("profile_pic");
let companySym;
// let companySym = `${baseurl}/getImages/companyLogoImg/?fileName=${companylog}`;

/////////////////////////////-------------------ClassComponents Start Here----------///////////////////////////
class PrintView extends Component {
  constructor(props) {
    super(props);

    let tempLogedIn = false;
    //////////////------------Token for Login prepose True and False Condition----------///////////////////////
    if (token != null) {
      tempLogedIn = true;
    }
    let propInvoiceId = this.props.location.state?.id;
    // let propInvoiceId = 316;
    propInvoiceId = propInvoiceId ? propInvoiceId : -1;
    console.log("this.prop", this.props);
    console.log("this.prop", this.props.location.state?.headerPrefix);
    //////////////-------Components this States here to use in only Class Components ------///////////////////////
    this.state = {
      logInStatus: tempLogedIn,
      invoiceNo: "",
      poNumber: "",
      issueDate: "",
      dueDate: "",
      companyName: "",
      clientName: "",
      viYearMake: "",
      zipCode: "",
      viModel: "",
      licenseNo: "",
      ExpenseDesc: "",
      vehicleIdNo: "",
      viMileage: "",
      city: "",
      tireFee: "",
      state: "",
      loadingResponce: false,
      setNextPrintPageFooter: false,
      setFooterData: [],
      labour: "",
      TireFee: "",
      TireDispossal: "",
      headerPrefix:
        this.props?.location?.state?.headerPrefix === "NOT GRN OR PO"
          ? "Invoice"
          : this.props?.location?.state?.headerPrefix,
      total_fet: 0,
      zip: "",
      disTotalItems: -1,
      sub_Amount_total_items: -1,
      total_Amount: -1,
      invoiceId: propInvoiceId,
      downloadLink: null,
    };
    thisContex = this;
    companySym = `${baseurl}/getImages/labelCard/?fileName=${companylog}`;
  }
  componentDidMount() {
    this.getPrintCall();
  }
  downloadInvoice() {
    const element = document.getElementById("pdfContent");
  }

  convertToCurrency(param_number) {
    return param_number !== 0 && param_number !== null && param_number > 0
      ? Number(
          param_number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        )?.toFixed(2)
      : 0;
  }
  getFooterDataWithId = (id) => {
    thisContex.setState({ loadingResponce: true });
    return axios
      .post(
        `${baseurl}/masterData/getDocFooterSetupId`,
        { id, companyId: companyId, username: username },
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then((res) => {
        let response = res.data.data[0];
        console.log("hhhhh", response);
        let label;
        if (response.page_size === "F") {
          label = "Full";
        } else {
          label = "Half";
        }
        const formValuesFooterData = [];

        if (response.desc1 !== " ") {
          formValuesFooterData.push({
            desc: response.desc1,
            enteredBy: response.Ent_by_1,
          });
        }
        if (response.desc_2 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_2,
            enteredBy: response.Ent_by_2,
          });
        }
        if (response.desc_3 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_3,
            enteredBy: response.Ent_by_3,
          });
        }
        if (response.desc_4 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_4,
            enteredBy: response.Ent_by_4,
          });
        }
        if (response.desc_5 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_5,
            enteredBy: response.Ent_by_5,
          });
        }
        if (response.desc_6 !== " ") {
          formValuesFooterData.push({
            desc: response.desc_6,
            enteredBy: response.Ent_by_6,
          });
        }
        console.log("hhhhh>", formValuesFooterData);
        if (formValuesFooterData.length > 3) {
          thisContex.setState({ setNextPrintPageFooter: true });
        } else {
          thisContex.setState({ setNextPrintPageFooter: false });
        }
        thisContex.setState({ setFooterData: formValuesFooterData });
        return formValuesFooterData.length;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(function () {
        thisContex.setState({ loadingResponce: false });
      });
  };
  getPrintCall() {
    let invLable;
    axios
      .post(`${baseurl}/auth/getCompany`, {
        username: username,
      })
      .then((response) => {
        var data = response.data.data;

        for (let index = 0; index < data.length; index++) {
          //  console.log('data :  >> ', data  , data[index].COMPANY_ID , data[index].LABEL , data[index].COMPANY_ID == companyId);
          if (data[index]?.COMPANY_ID == companyId)
            invLable = data[index]?.LABEL;
        }
        console.log("invLable......", invLable, companylog);
        companySym = `${baseurl}/getImages/labelCard/?fileName=${invLable}`;
      })
      .catch((error) => {
        console.log(error);
      });
    const { state } = this.props?.location;

    // let invoiceId = 7;
    if (this.state.invoiceId !== -1) {
      thisContex.setState({ loadingResponce: true }); //AHMAD

      if (state?.printType === "Purchase") {
        let endPoint =
          state?.headerPrefix === "PO"
            ? "getPrintPurchaseOrderView"
            : "getPrintPurchaseView";
        axios
          .post(
            `${baseurl}/masterData/${endPoint}`,
            {
              username,
              invoiceId: state?.id,
              companyId,
            },
            headers
          )
          .then(function (response) {
            let res_mt = response.data.data[0];
            let res_det = response.data.data1;
            let disTotalItems = 0;
            let sub_Amount_total_items = 0;
            let total_Amount = 0;
            let discount = 0;
            let invoiceNo = res_mt["DOCUMENT_NO"];
            let invBookId = res_mt["INV_BOOK_ID"];
            let postedYn = res_mt["posted_yn"];
            let labour = res_mt["LABOUR"];
            let TireDispossal = res_mt["total_Tire_Disposal"];
            let total_fet = res_mt["total_fet"];
            let tireFee = res_mt["totalTireFee"];
            let poNumber = res_mt["INVOICE_REF_NO"];
            let issueDate = res_mt["INVOICE_DATE"];
            let dueDate = res_mt["DUE_DATE"];
            let year_make = res_mt["year_make"];
            let viModel = res_mt["model"];
            let licenseNo = res_mt["license_no"];
            let remarks = res_mt["REMARKS"];
            let viMileage = res_mt["miles"];
            let city = res_mt["customer_city"];
            let vehicleIdNo = res_mt["vehicle_id_no"];
            let companyName = res_mt["Company_Name"];
            let clientName = res_mt["Client_Name"];
            let saleTaxAmount = res_det[0]?.staxAmount;
            let totalsalesTax = 0;
            let ExpenseDesc = res_det[0]?.["Expense_Desc"];
            let Item_Desc_Lov = res_det[0]?.["Item_Desc_Lov"];
            let address = res_mt["supplierAdress"];
            let email = res_mt["email"];
            let phone = res_mt["phone"];

            let CURRENCY_DESC = res_mt["CURRENCY_DESC"];
            let company_no = res_mt["company_no"];
            let dynamicInvMessage = "Record";
            // let headerPrefix =
            //   thisContex.props?.location?.state?.headerPrefix ===
            //   "NOT GRN OR PO"
            //     ? "Invoice"
            //     : thisContex.props?.location?.state?.headerPrefix;
            let state = res_mt["state_label"];
            let specificMsg = "";

            for (let index = 0; index < res_det.length; index++) {
              disTotalItems += res_det[index].TC_DISCOUNT;
              sub_Amount_total_items += res_det[index].TC_AMOUNT;
              totalsalesTax += res_det[index].salesTax;
              discount += res_det[index].TC_DISCOUNT;
              total_Amount += res_det[index].amount;
            }
            sub_Amount_total_items = sub_Amount_total_items - disTotalItems;
            if (UserName === "ishtiyak_master") {
              sub_Amount_total_items = sub_Amount_total_items + labour;
            }
            if (UserName === "ishtiyak_master") {
              total_Amount =
                total_Amount + TireDispossal + tireFee + labour + total_fet;
            }

            // console.log(res_det);
            if (invBookId === 1) {
              if (postedYn === 0) {
                dynamicInvMessage = "Invoice ( Draft )";
                specificMsg = "Purchase Invoice";
              } else if (postedYn == 1) {
                dynamicInvMessage = "Invoice";
              }
            } else if (invBookId === 2) {
              dynamicInvMessage = "Credit Note";
            } else if (invBookId === 4) {
              dynamicInvMessage = "Recurring";
            } else if (invBookId === 5) {
              dynamicInvMessage = "Subsistence";
            } else if (invBookId === 6) {
              dynamicInvMessage = "Purchase";
            } else if (invBookId === 7) {
              dynamicInvMessage = "Purchase Return";
            } else if (invBookId === 6) {
              dynamicInvMessage = "Purchase";
            } else if (invBookId === 9) {
              dynamicInvMessage = "ESTIMATE";
            } else if (invBookId === 10) {
              dynamicInvMessage = "Receipt";
            } else if (invBookId === 11) {
              dynamicInvMessage = "Journal Voucher";
            } else if (invBookId === 13) {
              dynamicInvMessage = "Customer Refund";
            } else if (invBookId === 14) {
              dynamicInvMessage = "Supplier Refund";
            } else if (invBookId === 22) {
              dynamicInvMessage = "Mileage";
            } else if (invBookId === 24) {
              dynamicInvMessage = "Supply Payments";
            } else if (invBookId === 25) {
              dynamicInvMessage = "Employee Payments";
            } else if (invBookId === 26) {
              dynamicInvMessage = "Purchase Recurring";
            }
            thisContex.getFooterDataWithId(res_mt["INV_BOOK_ID"]);

            thisContex.setState({
              loadingResponce: false,
              invoiceNo: invoiceNo,
              poNumber: poNumber,
              issueDate: moment(issueDate).format("DD MMMM YYYY"),
              dueDate: moment(dueDate).format("DD MMMM YYYY"),
              companyName: companyName,
              clientName: clientName,
              res_det: res_det,
              ExpenseDesc,
              Item_Desc_Lov,
              disTotalItems: disTotalItems,
              sub_Amount_total_items: sub_Amount_total_items,
              total_Amount: total_Amount,
              saleTaxAmount,
              discount,
              totalsalesTax,
              // salesTax,
              viYearMake: year_make,
              zipCode: res_mt?.zipCode,
              address,
              labour,
              TireDispossal,
              total_fet,
              viModel,
              tireFee,
              licenseNo,
              remarks,
              vehicleIdNo,
              viMileage,
              email,
              city,
              phone,
              company_no,
              CURRENCY_DESC,
              invBookId,
              dynamicInvMessage,
              // headerPrefix,
              specificMsg,
              state,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        axios
          .post(
            `${baseurl}/masterData/getPrintInvoiceView`,
            {
              username,
              invoiceId: this.state.invoiceId,
              companyId,
            },

            headers
          )
          .then(function (response) {
            // return
            let res_mt = response.data.data[0];
            let res_det = response.data.data1;
            let disTotalItems = 0;
            let sub_Amount_total_items = 0;
            let total_Amount = 0;

            let invoiceNo = res_mt?.["DOCUMENT_NO"];
            let invBookId = res_mt?.["INV_BOOK_ID"];
            let postedYn = res_mt?.["posted_yn"];
            let labour = res_mt?.["LABOUR"];
            let TireDispossal = res_mt?.["total_Tire_Disposal"];
            let total_fet = res_mt?.["total_fet"];
            let tireFee = res_mt?.["totalTireFee"];
            let poNumber = res_mt?.["INVOICE_REF_NO"];
            let issueDate = res_mt?.["INVOICE_DATE"];
            let dueDate = res_mt?.["DUE_DATE"];
            let year_make = res_mt?.["year_make"];
            let viModel = res_mt?.["model"];
            let licenseNo = res_mt?.["plate_no"];
            let remarks = res_mt?.["REMARKS"];
            let viMileage = res_mt?.["miles"];
            let city = res_mt?.["customer_city"];
            let vehicleIdNo = res_mt?.["vehicle_id_no"];
            let companyName = res_mt?.["Company_Name"];
            let clientName = res_mt?.["Client_Name"];
            let saleTaxAmount = res_det[0]?.["staxAmount"];
            let salesTax = res_det[0]?.["salesTax"];
            total_Amount = 0;
            let address = res_mt?.["customer_address"];
            let email = res_mt?.["email"];
            let phone = res_mt?.["customer_phone_no"];
            let CURRENCY_DESC = res_mt?.["CURRENCY_DESC"];
            let company_no = res_mt?.["company_no"];
            let dynamicInvMessage = "Record";
            let state = res_mt?.["state_label"];
            let specificMsg = "";

            // console.log(address);
            // console.log(email);
            // console.log(phone);
            // console.log(company_no);
            for (let index = 0; index < res_det.length; index++) {
              disTotalItems += res_det[index].TC_DISCOUNT;
              sub_Amount_total_items += res_det[index].TC_AMOUNT;
              total_Amount += res_det[index].amount;
            }
            sub_Amount_total_items = sub_Amount_total_items - disTotalItems;
            if (UserName === "ishtiyak_master") {
              sub_Amount_total_items = sub_Amount_total_items + labour;
            }
            if (UserName === "ishtiyak_master") {
              total_Amount = total_Amount + TireDispossal + tireFee + labour;
            }

            // console.log(res_det);
            if (invBookId === 1) {
              if (postedYn === 0) {
                dynamicInvMessage = "Invoice ( Draft )";
                specificMsg = "Invoice";
              } else if (postedYn == 1) {
                dynamicInvMessage = "Invoice";
              }
            } else if (invBookId === 2) {
              dynamicInvMessage = "Credit Note";
            } else if (invBookId === 4) {
              dynamicInvMessage = "Recurring";
            } else if (invBookId === 5) {
              dynamicInvMessage = "Subsistence";
            } else if (invBookId === 6) {
              dynamicInvMessage = "Purchase";
            } else if (invBookId === 7) {
              dynamicInvMessage = "Purchase Return";
            } else if (invBookId === 6) {
              dynamicInvMessage = "Purchase";
            } else if (invBookId === 9) {
              dynamicInvMessage = "ESTIMATE";
            } else if (invBookId === 10) {
              dynamicInvMessage = "Receipt";
            } else if (invBookId === 11) {
              dynamicInvMessage = "Journal Voucher";
            } else if (invBookId === 13) {
              dynamicInvMessage = "Customer Refund";
            } else if (invBookId === 14) {
              dynamicInvMessage = "Supplier Refund";
            } else if (invBookId === 22) {
              dynamicInvMessage = "Mileage";
            } else if (invBookId === 24) {
              dynamicInvMessage = "Supply Payments";
            } else if (invBookId === 25) {
              dynamicInvMessage = "Reimburse Employee";
            } else if (invBookId === 26) {
              dynamicInvMessage = "Purchase Recurring";
            }

            thisContex.setState({
              loadingResponce: false,
              invoiceNo: invoiceNo,
              poNumber: poNumber,
              issueDate: moment(issueDate).format("DD MMMM YYYY"),
              dueDate: moment(dueDate).format("DD MMMM YYYY"),
              companyName: companyName,
              clientName: clientName,
              res_det: res_det,
              disTotalItems: disTotalItems,
              sub_Amount_total_items: sub_Amount_total_items,
              total_Amount: total_Amount ? total_Amount : 0,
              saleTaxAmount,
              salesTax,
              viYearMake: year_make,
              zipCode: res_mt?.zipCode,
              address,
              labour,
              TireDispossal,
              total_fet,
              viModel,
              tireFee,
              licenseNo,
              remarks,
              vehicleIdNo,
              viMileage,
              email,
              city,
              phone,
              company_no,
              CURRENCY_DESC,
              invBookId,
              dynamicInvMessage,
              specificMsg,
              state,
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }
  goBackToInvoice() {
    console.log("ridirect............", this.props.location.state?.updateCheck);
    console.log("ridirect............", this.props.location.state);
    this.setState({
      goBack: true,
    });
    // return (
    //   <Redirect
    //     // to="/CreativeInvoice"
    //     push
    //     to={{
    //       pathname: "/CreativeInvoice",
    //       state: {
    //         id: 12,
    //       },
    //     }}
    //   />
    // );
  }

  render() {
    const {
      invoiceId,
      logInStatus,
      invoiceNo,
      poNumber,
      issueDate,
      dueDate,
      companyName,
      clientName,
      disTotalItems,
      sub_Amount_total_items,
      total_Amount,
      totalsalesTax,
      discount,
      saleTaxAmount,
      salesTax,
      phone,
      email,
      ExpenseDesc,
      Item_Desc_Lov,
      tireFee,
      viMake,
      viMileage,
      TireDispossal,
      total_fet,
      viModel,
      viYear,
      viYearMake,
      zipCode,
      licenseNo,
      remarks,
      vehicleIdNo,
      city,
      labour,
      state,
      zip,
      company_no,
      address,
      goBack,
      CURRENCY_DESC,
      dynamicInvMessage,
      headerPrefix,
      loadingResponce,
      specificMsg,
    } = this.state;
    if (!logInStatus) return <Redirect to="/login" />;
    else if (loadingResponce) {
      return <Responseloader />;
    } else if (goBack) {
      return (
        <Redirect
          // to="/CreativeInvoice"
          push
          to={{
            pathname: this.props.location.state?.url,
            state: {
              id: this.state.invoiceId,
              updateCheck: this.props.location.state?.updateCheck, //AHMAD
              // updateCheck: 2, //AHMAD
            },
          }}
        />
      );
    } else if (invoiceId !== -1) {
      return (
        <div id="pdfContent">
          {UserName === "ishtiyak_master" ? (
            <Row className="app-body-Invoice bg-white rounded-lg shadow py-2">
              <Col xs="12">
                {/* invoice print head ishtiaq tire inv print view 
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary "
                    id="printPageButton"
                    onClick={this.downloadInvoice}
                  >
                    Download Invoice
                  </button>
                </div>  */}
                <div id="pdfContent">
                  <br></br>
                  <br></br>
                  <Container>
                    <Row className="my-2">
                      <Col md="6">
                        <img
                          src={FolsomLogo}
                          alt="FOLSOM TIRE & WHEEL"
                          className=" "
                          width={360}
                          height={120}
                        />
                      </Col>
                      <Col md="6">
                        <div style={{ textAlign: "end" }}>
                          <button
                            className="btn btn-primary mx-2 btn-sm"
                            id="printPageButton"
                            onClick={() => {
                              window.print();
                            }}
                          >
                            Print to PDF!
                          </button>
                          <button
                            className="btn btn-primary m-1  btn-sm"
                            id="printPageButton"
                            onClick={() => {
                              this.goBackToInvoice();
                            }}
                          >
                            Back
                          </button>
                        </div>
                        <h3 className="text-right pr-2 my-0">
                          INVOICE :
                          <span className="text-muted">{invoiceNo}</span>
                        </h3>
                        <hr className="border my-0  w-50 float-right " />
                        <br />
                        <h6 className="text-right text-muted">
                          9565 Folsom Blvd., Sacramento, CA 95827 <br />
                          Tel: (916)706-1745 • Fax: (916) 538-6346 <br />
                          folsomtireandwheels@hotmail.com{" "}
                        </h6>
                      </Col>
                    </Row>
                  </Container>

                  <Row className="my-1">
                    <Container>
                      <Row>
                        <Col md="5" className="border">
                          {" "}
                          <h5>
                            Name :{" "}
                            <span className="h5 text-muted">{clientName}</span>
                          </h5>
                        </Col>
                        <Col md="4" className="border">
                          {" "}
                          <h5>
                            Phone :{" "}
                            <span className="h5 text-muted">{phone}</span>
                          </h5>{" "}
                        </Col>
                        <Col md="3" className="border">
                          <h5>
                            Date :{" "}
                            <span className="h5 text-muted">
                              {moment(issueDate).format("MM DD YYYY")}
                            </span>
                          </h5>
                          {/* <p style={{ textAlign: "right" }}>
                          Due Date : {dueDate}
                        </p> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="border">
                          <h5>
                            Address :{" "}
                            <span className="h5 text-muted">{address}</span>
                          </h5>
                        </Col>
                        <Col md="3" className="border">
                          <h5>
                            Year-Make :{" "}
                            <span className="h5 text-muted">{viYearMake}</span>
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" className="border">
                          <h5>
                            City : <span className="h5 text-muted">{city}</span>
                          </h5>
                        </Col>
                        <Col md="3" className="border">
                          <h5>
                            State :{" "}
                            <span className="h5 text-muted">{state}</span>
                          </h5>
                        </Col>
                        <Col md="2" className="border">
                          <h5>
                            Zip :{" "}
                            <span className="h5 text-muted">{zipCode}</span>
                          </h5>
                        </Col>
                        <Col md="3" className="border">
                          <h5>
                            Model :{" "}
                            <span className="h5 text-muted">{viModel}</span>
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="border">
                          <h5>
                            Vehicle Id No :{" "}
                            <span className="h5 text-muted">
                              {vehicleIdNo || ""}
                            </span>
                          </h5>
                        </Col>
                        <Col md="3" className="border">
                          <h5>
                            License Plate No :{" "}
                            <span className="text-muted"> {licenseNo}</span>
                          </h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="9" className="border py-2">
                          <Row>
                            <Col sx="3">
                              {" "}
                              <FormGroup check>
                                <Input type="checkbox" />{" "}
                                <Label check>CASH</Label>
                              </FormGroup>
                            </Col>
                            <Col sx="3">
                              <FormGroup check>
                                <Input type="checkbox" />{" "}
                                <Label check>CHARGE</Label>
                              </FormGroup>
                            </Col>
                            <Col sx="3">
                              <FormGroup check>
                                <Input type="checkbox" />{" "}
                                <Label check>CHECK</Label>
                              </FormGroup>
                            </Col>
                            <Col sx="3">
                              <FormGroup check>
                                <Input type="checkbox" />{" "}
                                <Label check>FINANCE</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="3" className="border">
                          <h5>
                            Mileage :{" "}
                            <span className="h5 text-muted">{viMileage}</span>
                          </h5>
                        </Col>
                      </Row>
                      {remarks !== "undefined" && remarks && (
                        <Row>
                          <Col md="12" className="border">
                            <h6>
                              {/* Notes visible to the client on the invoice:{" "} */}
                              Remarks :
                              <span
                                className="text-muted"
                                style={{
                                  fontSize: "12px",
                                  fontStyle: "italic",
                                }}
                              >
                                {" "}
                                {remarks}
                              </span>
                            </h6>
                          </Col>
                        </Row>
                      )}
                    </Container>
                  </Row>
                  <Row className="my-1">
                    <Container className="border" style={{ height: "31rem" }}>
                      <Row>
                        {/* <Col md="1" className="border">
                          <h4>Code </h4>
                        </Col> */}
                        <Col md="1" className="border text-right">
                          <h6 style={{}}>Qty</h6>
                        </Col>
                        <Col md="4" className="border">
                          <h6>Item</h6>{" "}
                        </Col>
                        <Col md="5" className="border">
                          <h6 style={{}}>Description</h6>
                        </Col>
                        <Col md="1" className="border text-right">
                          <h6 style={{}}>Price</h6>
                        </Col>
                        <Col md="1" className="border">
                          <h6 className="text-right">Amount</h6>
                        </Col>
                      </Row>
                      <div className=" px-3 mx-n3">
                        {this.state.res_det?.map((itm, index) => {
                          return (
                            <Row className="border-bottom " key={index}>
                              {/* <Col md="1" className="border text-center">
                                <p>{itm.ITEM_CODE} </p>
                              </Col> */}
                              <Col md="1" className="  text-right">
                                <p className="mb-0">
                                  {itm.PRIMARY_QTY.toString()}
                                </p>
                              </Col>
                              <Col md="4" className=" ">
                                <p className="mb-0">
                                  {` ${itm.ITEM_CODE} ${itm.Item_Desc_Lov}  `}
                                </p>
                                {/* <span>{itm.ITEM_DESC || ""}</span>{" "} */}
                              </Col>
                              <Col md="5" className="">
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "12px",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {itm.ITEM_DESC}
                                </p>
                              </Col>
                              <Col md="1" className="px-1">
                                <p className="mb-0 text-right">
                                  {CURRENCY_DESC}{" "}
                                  {(itm.TC_AMOUNT / itm.PRIMARY_QTY)
                                    ?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                              </Col>
                              <Col md="1" className="px-1">
                                <p className="mb-0 text-right">
                                  {CURRENCY_DESC}{" "}
                                  {itm.TC_AMOUNT?.toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    </Container>
                  </Row>
                  <Container>
                    <Row>
                      <Col xs="8" className="border">
                        <h5 className="px-3 pt-2">
                          SPECIAL ORDER: NO REFUNDS AND NO EXCHANGE
                        </h5>
                        <ul className="mb-0">
                          <li>
                            Balanced Tires and Performed Alignment on Customer
                            Request - Initial: ___________
                          </li>
                          <li>
                            TIRE PRESSURE SET ACCORDING TO FACTORY
                            SPECIFICATIONS
                          </li>

                          <li>
                            New or Used Tires Shown to Customer Before
                            Installation
                          </li>
                          <li>
                            {" "}
                            Vehicle Was Not Aligned Properly At Time Of
                            Installment
                          </li>
                          <li>
                            Installed on Customer Request : Initial ___________
                          </li>
                        </ul>
                        <Row>
                          <Col xs="6">
                            <ul>
                              <li>
                                Torque All Lugnuts by Factory Specifications
                              </li>
                              <li> No Warranty on Low Profile Tire</li>
                              <li> New Valve Stem Installed</li>
                              <li>No Warranty ___________</li>
                              <li>No Road Hazardous</li>
                            </ul>
                          </Col>
                          <Col xs="6">
                            <ul>
                              <li>No Warranty on Used Tire</li>

                              <li>Customer Shown DOT Date of Manufacturing</li>
                              <li> Rotation (5000 miles)</li>
                              <li>Checked in water</li>
                              <li>Flat Repair</li>
                            </ul>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="4" className="border">
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">LABOUR:</h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {" "}
                              {CURRENCY_DESC} {this.convertToCurrency(labour)}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">SUB TOTAL</h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {CURRENCY_DESC}{" "}
                              {sub_Amount_total_items
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">
                              SALES TAX ({salesTax}%)
                            </h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {CURRENCY_DESC}{" "}
                              {saleTaxAmount
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">
                              NEW TIRE FEE:
                            </h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {" "}
                              {CURRENCY_DESC} {this.convertToCurrency(tireFee)}
                              {/* {tireFee
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">
                              TIRE DISPOSSAL
                            </h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {" "}
                              {CURRENCY_DESC}{" "}
                              {/* {this.convertToCurrency(TireDispossal)} */}
                              {Number(
                                TireDispossal?.toString()?.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              )?.toFixed(2)}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">FET</h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {" "}
                              {CURRENCY_DESC}{" "}
                              {/*{this.convertToCurrency(total_fet)} */}
                              {total_fet
                                ?.toFixed(2)
                                ?.toString()
                                ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">TOTAL</h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0">
                              {CURRENCY_DESC}{" "}
                              {total_Amount
                                ?.toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">DEPOSIT</h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0"></h6>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6" className="border">
                            {" "}
                            <h6 className="text-muted  py-1 mb-0">
                              BALANCE DUE
                            </h6>
                          </Col>
                          <Col xs="6" className="border">
                            <h6 className="text-muted float-right py-1 mb-0"></h6>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="pt-1">
                        <br></br>
                        <br></br>
                        <h5>
                          CUSTOMERS: PLEASE NOTE: NOT RESPONSIBLE FOR BROKEN OR
                          LOST HUB CAPS{" "}
                        </h5>
                        <p>
                          Company does not provide any kind of warranty on used
                          tires and no warranty on shaved white wall TOTAL
                          tires. The company will not be responsible if anything
                          happens to you or your vehicle due to used tires. All
                          warranties on the products sold hereby are those made
                          by the manufacturer. The seller (above-named
                          dealership) hereby expressly disclaims all warranties,
                          either expressed or implied, including any implied
                          DEPOSIT warranty of merchantability or fitness for
                          purpose, and neither assumes nor authorizes any other
                          person to assume for it any LIABILITY in connection
                          with the safety of said products. Buyer shall not be
                          entitled BALANCE to recover from seller any
                          consequential damages to property, damage for a time,
                          loss of profit or income, DUE, or any other incidental
                          damage. No Warranty on Aftermarket Wheels. Folsom Tire
                          and Wheels will not provide any kind of Warranty at
                          all on economy tires and wheels. Folsom Tires and
                          Wheels will not be responsible for any damages to car
                          prior to service.
                        </p>
                        <p>
                          Customer has inspected the car prior to leaving -
                          Initial: _____________
                          <br />
                          I have read all information above carefully. The
                          company is not liable for any damages incurred after
                          vehicle has left the shop - Initial:_____________.
                          <br />
                          NO RETURNS ON ANY ITEMS SOLD BY FOLSOM TIRE AND
                          WHEELS. NO CASH REFUND CUSTOMER <br />
                          SIGNATURE
                          __________________________________________________
                          Company will not be responsible for any item left over
                          3 days.
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          ) : (
            <Row className="app-body-Invoice bg-white">
              <Col xs="12">
                <Row className="">
                  <Col xs="4" className="px-5 row">
                    <ReactRoundedImage
                      className="ReactRoundedImage"
                      image={companySym}
                      roundedColor="#321124"
                      imageWidth="50"
                      imageHeight="45"
                      roundedSize="4"
                    />
                    <h1>{companyName}</h1>
                  </Col>
                  <Col xs="5">
                    {" "}
                    <h2 className="text-center">
                      <u>
                        {headerPrefix} {dynamicInvMessage}
                      </u>
                    </h2>{" "}
                  </Col>
                  <Col xs="3" className="px-4">
                    <div style={{ textAlign: "end" }}>
                      <button
                        className="btn btn-primary mx-2"
                        id="printPageButton"
                        onClick={() => {
                          window.print();
                        }}
                      >
                        Print to PDF!
                      </button>
                      <button
                        className="btn btn-primary m-1"
                        id="printPageButton"
                        onClick={() => {
                          this.goBackToInvoice();
                        }}
                      >
                        Back
                      </button>
                    </div>{" "}
                  </Col>
                </Row>
                <br></br>
                <hr
                  style={{
                    marginTop: "-0.7rem",
                    border: "1px solid Light gray",
                  }}
                />
                <Row className="px-5 display-flex flex">
                  <Col
                    xs="6"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <h2>
                      <b>{clientName}</b>
                    </h2>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: "8px",
                      }}
                    >
                      <h4 style={{ marginRight: "10px", minWidth: "100px" }}>
                        Address:
                      </h4>
                      <span style={{ marginLeft: "10px" }}>
                        <h5>{address}</h5>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: "8px",
                      }}
                    >
                      <h4 style={{ marginRight: "10px", minWidth: "100px" }}>
                        Phone:
                      </h4>
                      <span style={{ marginLeft: "10px" }}>
                        <h5>{phone}</h5>
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: "8px",
                      }}
                    >
                      <h4 style={{ marginRight: "10px", minWidth: "100px" }}>
                        E-Mail:
                      </h4>
                      <span style={{ marginLeft: "10px" }}>
                        <h5>{email}</h5>
                      </span>
                    </div>
                    {/* Uncomment if needed */}
                    {/* <div style={{ display: "flex", alignItems: "baseline", marginBottom: "8px" }}>
      <h3 style={{ marginRight: "10px", minWidth: "100px" }}><b>Reg #:</b></h3>
      <span style={{ marginLeft: "10px" }}>{company_no}</span>
    </div> */}
                  </Col>
                  <Col
                    xs="6"
                    className="px-5"
                    style={{
                      marginLeft: "80%", // Adjust the horizontal position as needed
                      marginTop: "-11%", // Adjust the vertical position as needed
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start", // Align all items to the start of the column
                    }}
                  >
                    {/* Invoice Message and Number */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: "8px",
                      }}
                    >
                      <h4 style={{ marginRight: "10px", minWidth: "100px" }}>
                        {specificMsg ? specificMsg : dynamicInvMessage} No:
                      </h4>
                      <span style={{ marginLeft: "0px" }}>
                        <h5>{invoiceNo}</h5>
                      </span>
                    </div>

                    {/* PO Number (conditionally rendered) */}
                    {poNumber && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          marginBottom: "8px",
                        }}
                      >
                        <h4 style={{ marginRight: "10px", minWidth: "100px" }}>
                          PO Number:
                        </h4>
                        <span style={{ marginLeft: "0px" }}>
                          <h5>{poNumber}</h5>
                        </span>
                      </div>
                    )}

                    {/* Issue Date */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: "8px",
                      }}
                    >
                      <h4 style={{ marginRight: "10px", minWidth: "100px" }}>
                        Issue Date:
                      </h4>
                      <span style={{ marginLeft: "19px" }}>
                        <h5>{issueDate}</h5>
                      </span>
                    </div>

                    {/* Due Date */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        marginBottom: "8px",
                      }}
                    >
                      <h4 style={{ marginRight: "10px", minWidth: "100px" }}>
                        Due Date:
                      </h4>
                      <span style={{ marginLeft: "25px" }}>
                        <h5>{dueDate}</h5>
                      </span>
                    </div>
                  </Col>
                </Row>
                <hr
                  style={{
                    border: "1px solid Light gray",
                  }}
                />
                <Row xs="12" className="px-5">
                  <Col xs="12" className="">
                    <table style={{ width: "100%" }}>
                      <colgroup>
                        <col style={{ width: "40%" }} />{" "}
                        {/* First column 30% */}
                        <col style={{ width: "5%" }} /> {/* Unit column */}
                        <col style={{ width: "5%" }} /> {/* Qty column */}
                        <col style={{ width: "10%" }} /> {/* Rate column */}
                        <col style={{ width: "10%" }} />{" "}
                        {/* Before Tax Amount column */}
                        <col style={{ width: "10%" }} />{" "}
                        {/* Sales Tax Amount column */}
                        <col style={{ width: "10%" }} /> {/* Discount column */}
                        <col style={{ width: "10%" }} />{" "}
                        {/* After Tax Amount column */}
                      </colgroup>
                      <thead>
                        <tr>
                          {Item_Desc_Lov ? <th>Item</th> : <th>Item</th>}
                          <th>Unit</th>
                          <th>Qty</th>
                          <th className="text-right">Rate</th>
                          <th className="text-right">
                            Before Tax<br></br>
                            Amount
                          </th>
                          <th className="text-right">Discount</th>

                          <th className="text-right">
                            Sales Tax<br></br>
                            Amount
                          </th>
                          <th className="text-right">
                            After Tax<br></br>
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.res_det?.map((itm, index) => {
                          return (
                            <tr key={index}>
                              {itm.Item_Desc_Lov ? (
                                <td>
                                  {itm.ITEM_CODE} {itm.Item_Desc_Lov}
                                </td>
                              ) : (
                                <td>{itm.Expense_Desc}</td>
                              )}
                              <td>
                                {itm.UOM_DESC && itm.UOM_DESC != "undefined"
                                  ? itm.UOM_DESC.toString()
                                  : ""}
                              </td>
                              <td className="text-right">
                                {/* {itm.PRIMARY_QTY &&
                                  itm.PRIMARY_QTY !=
                                    "undefined"
                                      ?.toFixed(2)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ","):""} */}
                                {itm.PRIMARY_QTY?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="text-right">
                                {/* {CURRENCY_DESC}{" "} */}
                                {(itm.TC_AMOUNT / itm.PRIMARY_QTY)
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="text-right">
                                {/* {CURRENCY_DESC}{" "} */}
                                {itm.TC_AMOUNT?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="text-right">
                                {/* {CURRENCY_DESC}{" "} */}
                                {itm.TC_DISCOUNT?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                              <td className="text-right">
                                {/* {CURRENCY_DESC}{" "} */}
                                {itm.salesTax
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>

                              <td className="text-right">
                                {/* {CURRENCY_DESC}{" "} */}
                                {itm.amount
                                  ?.toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </Col>
                  {/* {disTotalItems > 0 && (
                    <Col xs="12">
                      <h5 className="text-muted float-right pt-2 ">
                        Discount: {CURRENCY_DESC}{" "}
                        {disTotalItems
                          ?.toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </h5>
                    </Col>
                  )} */}
                  <Col xs="12" className="">
                    <hr
                      style={{
                        border: "1px solid Light gray",
                      }}
                    />
                  </Col>
                  <Col xs="5" className="">
                    <h5 style={{ textAlign: "left" }}>
                      <b>Total({CURRENCY_DESC})</b>
                    </h5>

                    <hr
                      style={{
                        border: "1px solid Light gray",
                      }}
                    />
                  </Col>
                  <Col xs="5" className="" style={{ marginLeft: "-3rem" }}>
                    <h5 style={{ textAlign: "right" }}>
                      <b>
                        {/* {CURRENCY_DESC}{" "} */}
                        {discount
                          ?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </b>
                    </h5>
                    {/* <h5 style={{ textAlign: "right" }}>
                      {CURRENCY_DESC}{" "}
                      {sub_Amount_total_items
                        ?.toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </h5> */}
                    <hr
                      style={{
                        border: "1px solid Light gray",
                      }}
                    />
                  </Col>
                  <Col xs="1" className="" style={{ marginLeft: "2.8rem" }}>
                    <h5 style={{ textAlign: "" }}>
                      {totalsalesTax ? (
                        <b>
                          {/* {CURRENCY_DESC}{" "} */}
                          {totalsalesTax
                            ?.toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </b>
                      ) : null}
                    </h5>

                    <hr
                      style={{
                        border: "1px solid Light gray",
                      }}
                    />
                  </Col>

                  <Col xs="1" className="" style={{ textAlign: "right" }}>
                    <h5 className="">
                      <b>
                        {/* {CURRENCY_DESC}{" "} */}
                        {total_Amount
                          ?.toFixed(2)
                          // .toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </b>
                    </h5>
                    <hr
                      style={{
                        border: "1px solid Light gray",
                      }}
                    />
                  </Col>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Col xs="10" className="">
                    <h4
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Payment Terms: Invoice to be settled by {dueDate}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Container
                className={`${
                  this.state.setNextPrintPageFooter ? "footerNewPage" : "footer"
                }`}
                // className="footer"
                style={{ backgroundColor: "white" }}
              >
                {/* <Col md="4">
                    <h4>Naration:</h4>
                  </Col>
                  <Col
                    md="12"
                    className="border"
                    style={{ height: "4rem", marginBottom: "5rem" }}
                  >
                    <p>{printData[0]?.naration}</p>
                  </Col> */}
                <Row
                  className="mt-5 footerRows"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {this.state.setFooterData.map((row) => (
                    <Col
                      lg="4"
                      md="4"
                      sm="4"
                      className="px-5"
                      style={{
                        marginBottom: "3rem",
                        marginTop: row.enteredBy != 1 ? "1.3rem" : "0",
                      }}
                    >
                      <h5>
                        {" "}
                        {row.enteredBy
                          ? row.enteredBy /*printData[0]?.name*/
                          : "  "}
                      </h5>
                      <hr
                        style={{
                          border: "1px solid ",
                          borderColor: "E4E5E6",
                          margin: "revert",
                        }}
                      />
                      <h4 key={row.id}>{row.desc}</h4>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Row>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <Row className="app-body-Invoice">
            <Col>
              <div
                className="col-md-12"
                style={{ width: "1100px", margin: "0 auto" }}
              >
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 style={{ fontWeight: "bold" }}>
                        <Redirect to="/OverviewInvoice" />
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

export default PrintView;
